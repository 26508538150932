import { defineMessage } from '@lingui/macro'

export const teacherEducationalQualificationOptions = [
  { value: 'Post Graduate', label: 'Post Graduate' },
  { value: 'Graduate', label: 'Graduate' },
  { value: 'Higher Secondary', label: 'Higher Secondary' },
  { value: 'Matriculation', label: 'Matriculation' },
]

export const teacherExperienceOptions = [
  { value: 'Professional', label: 'Professional' },
  { value: 'Intermediate', label: 'Intermediate' },
  { value: 'Beginner', label: 'Beginner' },
  { value: 'No experience', label: 'No experience' },
]

export const genderOptions = [
  {
    value: 'Male',
    label: defineMessage({ id: 'dropdown.gender.male', message: 'Male' }),
  },
  {
    value: 'Female',
    label: defineMessage({ id: 'dropdown.gender.female', message: 'Female' }),
  },
  {
    value: null,
    label: defineMessage({
      id: 'dropdown.gender.unspecified',
      message: 'Unspecified',
    }),
  },
]

export const eventMap = {
  'Available slot': {
    backgroundColor: '#D5F0DA',
    color: '#4AAF50',
  },
  'Booked slot': {
    backgroundColor: '#CCEAFE',
    color: '#1F95F2',
  },
  'Canceled slot': {
    backgroundColor: '#FAE9E6',
    color: '#D41F00',
  },
  'Trial slot': {
    backgroundColor: '#F2E5F2',
    color: '#800080',
  },
}

export const trialClassNotifications = {
  NO_SLOT_AVAILABLE: 'No trial slot available',
  NOTIFY_WHEN_AVAILABLE: 'Notify when trial slots are available',
}

export const GENDER = {
  MALE: 'Male',
  FEMALE: 'Female',
  UNSPECIFIED: null,
}

// Flipkart offer 1
export const FLIPKART_OFFERS_STEP_ENUM = {
  loading: 0,
  enterDetails: 1,
  enterVoucher: 2,
  bookFreeClass: 3,
  makePayment: 4,
  paymentConfirmation: 5,
  paymentSuccess: 6,
}

// Flipkart offer 2
export const FLIPKART_DEALS_STEP_ENUM = {
  loading: 0,
  enterDetails: 1,
  enterVoucher: 2,
  confirmation: 3,
  success: 4,
}

export const CLASS_VIDEO_UPLOAD_STATUS = {
  IN_PROGRESS: 'in_progress',
  COMPLETE: 'complete',
}

export const TRIAL_BOOKING_STATE = {
  FAIL: 'fail',
}

export const SEGMENT_ENUM = {
  SAW_INSTANT_CLASS_OPTION: 'web:student:saw_instant_class_option',
  INSTANT_CLASS_BOOKED: 'web:student:instant_class_booked',
  INSTANT_CLASS_LOBBY_TIMEOUT: 'web:student:instant_class_lobby_timeout',
  CANCEL_INSTANT_REQUEST: 'web:student:cancel_instant_request',
  BOOK_INSTANT_TRIAL_CLASS: 'web:student:book_instant_trial_class',
  BOOK_SLOT: 'web:student:book_slot',
  TIMEOUT_TRY_AGAIN: 'web:student:timeout_try_again',
  TIMEOUT_BOOK_SLOT: 'web:student:timeout_book_slot',
  TEACHER_INSTANT_REQUEST: 'web:teacher:teacher_instant_request',
  ACCEPT_INSTANT_REQUEST: 'web:teacher:accept_instant_request',
  DENY_INSTANT_REQUEST: 'web:teacher:deny_instant_request',
}

export const HOMEPAGE_SEGMENT_ENUM = {
  TRACK_ANONYMOUS_USER_VISIT: 'web:homepage:visit_anon_user',
  TRACK_BOOK_FREE_CLASS_FIRST_FOLD_CTA_CLICK:
    'web:homepage:student:click_book_free_class_with_phone_number',
  TRACK_LOGIN_HEADER_CTA_CLICK: 'web:homepage:student:click_login_on_header',
  TRACK_JOIN_CLASS_HEADER_CTA_CLICK:
    'web:homepage:student:click_join_class_on_header',
  TRACK_BOOK_FREE_CLASS_HEADER_CTA_CLICK:
    'web:homepage:student:click_book_free_class_on_header',
}

export const PHONE_VERIFICATION_SCREEN_SEGMENT_ENUM = {
  TRACK_STUDENT_VISIT_AFTER_REGISTRATION:
    'web:phone_verify:student:visit_after_registration',
  TRACK_OTP_ENTER: 'web:phone_verify:student:otp_enter',
  TRACK_OTP_VERIFICATION_SUCCESS: 'web:phone_verify:student:otp_success',
  TRACK_RESEND_OTP_WP: 'web:phone_verify:student:otp_resend_whatsapp',
  TRACK_RESEND_OTP_SMS: 'web:phone_verify:student:otp_resend_sms',
  TRACK_RESEND_OTP_CALL: 'web:phone_verify:student:otp_resend_call',
  TRACK_RESEND_OTP_EMAIL: 'web:phone_verify:student:otp_resend_email',
}

export const TRIAL_CLASS_BOOKING_SCREEN_SEGMENT_ENUM = {
  TRACK_STUDENT_VISIT: 'web:trial_class_slots:student:visit',
  TRACK_STUDENT_DATE_CLICK: 'web:trial_class_slots:student:click_date',
  TRACK_STUDENT_TIME_CLICK: 'web:trial_class_slots:student:click_time',
  TRACK_STUDENT_BOOK_NOW_CTA_CLICK:
    'web:trial_class_slots:student:click_book_now',
  TRACK_STUDENT_SELECT_DATE_TIME_ERROR:
    'web:trial_class_slots:student:view_error_select_date_time',
}

export const TRIAL_CLASS_JOIN_SCREEN_SEGMENT_ENUM = {
  TRACK_STUDENT_VISIT: 'web:trial_class_join:student:visit',
  TRACK_RUN_COMPUTER_CHECK_CTA_CLICK:
    'web:trial_class_join:student:click_run_computer_check',
  TRACK_COMPUTER_CHECK_SUCCESS:
    'web:trial_class_join:student:computer_check_success',
  TRACK_COMPUTER_CHECK_ERROR:
    'web:trial_class_join:student:computer_check_error',
  TRACK_JOIN_CLASS_CTA_CLICK: 'web:trial_class_join:student:click_join_class',
  TRACK_TEST_INTERNET_SPEED_CTA_CLICK:
    'web:trial_class_join:student:click_test_internet_speed',
  TRACK_DOWNLOAD_GOOGLE_CHROME_CTA_CLICK:
    'web:trial_class_join:student:click_download_google_chrome',
}

export const CLASSROOM_SEGMENT_ENUMS = {
  TRACK_CLASSROOM_WAITING_SCREEN_VISIT: 'web:classroom_waiting:student:visit',
}

export const REGISTER_DETAILS_SEGMENT_ENUM = {
  TRACK_VISIT: 'web:register_details:visit_anon_user',
  TRACK_PROCEED_CTA_CLICK: 'web:register_details:click_proceed',
}

export const ROUTES = {
  FEEDBACK: '/feedback/[userType]/[classType]/[classroomId]',
  TRIAL_CLASS_INCOMPLETE: '/trial-class/incomplete/',
  QUIZZES_LIST: '/quizzes/',
  QUIZZES_LEADERBOARD: '/quizzes/leaderboard/',
  STUDENT_DASHBOARD_QUIZZES_LIST: '/student/dashboard/quizzes/',
  REGISTER: '/register/',
}

export const ABSOLUTE_ROUTES = {
  REGISTER: 'https://www.codingal.com/register/',
  REVIEWS: 'https://www.codingal.com/reviews-and-testimonials/',
  HPE_CODEWARS_2022: 'https://www.codingal.com/hpe-codewars-2022/',
  REFERRAL_TYPEFORM: 'https://codingal.typeform.com/to/LwAV6FIG',
}
